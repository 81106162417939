import React from 'react';
import './App.css';
import ShreeyashHaritashyaPage from './shreeyash_haritashya';

function App() {
  return (
    <div className="App">
      <div className="bg-image"></div>
      <div className="content">
        <main>
          <ShreeyashHaritashyaPage />
        </main>
      </div>
    </div>
  );
}

export default App;
