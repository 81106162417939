import React from "react";
import { CSSProperties } from "react";

const ShreeyashHaritashyaPage: React.FC = () => {
  return (
    <div style={styles.container}>
      {/* Header Section */}
      <header style={styles.header}>
        <h1 style={styles.title}>Shreeyash Haritashya & Family</h1>
      </header>

      {/* Main Content */}
      <main style={styles.main}>
        {/* About Section */}
        <section style={styles.section}>
          <img
            src="https://example.com/shreeyash-image.jpg"
            alt="Shreeyash Haritashya"
            style={styles.image}
          />
          <div style={styles.textContent}>
            <h2 style={styles.subtitle}>About Shreeyash</h2>
            <p>
              Shreeyash Haritashya is the Co-Founder and CEO of{" "}
              <a
                href="https://neurastats.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Neurastats
              </a>
              , a company specializing in AI-powered data analysis and
              visualization.
            </p>
          </div>
        </section>

        {/* Interests Section */}
        <section style={styles.section}>
          <h2 style={styles.subtitle}>Interests</h2>
          <ul>
            <li>Artificial Intelligence</li>
            <li>Data Science</li>
            <li>Entrepreneurship</li>
            <li>Family Time</li>
          </ul>
        </section>
      </main>

      {/* Footer */}
      <footer style={styles.footer}>
        <p>&copy; {new Date().getFullYear()} Neurastats Pvt Ltd</p>
      </footer>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    textAlign: "center",
    padding: "20px 0",
  },
  title: {
    fontSize: "2.5em",
    color: "#333",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  section: {
    marginBottom: "30px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "8px",
  },
  textContent: {
    textAlign: "left",
  },
  subtitle: {
    fontSize: "1.8em",
    color: "#444",
    marginBottom: "10px",
  },
  footer: {
    textAlign: "center",
    padding: "20px 0",
    borderTop: "1px solid #eee",
  },
};

export default ShreeyashHaritashyaPage;
